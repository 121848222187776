import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import cx from 'classnames';

import { getPrice } from 'utils/getPrice';
import trackEvent from 'utils/trackEvent';

import * as styles from './ProductPreview.module.css';

export default function ProductPreview({
  path,
  product,
  index,
  listName = 'Default List',
}) {
  if (!product) return null;

  const { price, compareAtPrice, isOnSale } = getPrice(product);
  const isSoldOut =
    product.variants.filter((v) => v.availableForSale)?.length === 0;

  const handleClick = () => {
    trackEvent('click', { product, index, listName });
  };

  return (
    <Link
      to={`/collections/${path}/products/${product.handle}/`}
      onClick={handleClick}
    >
      {product.featuredMedia && (
        <GatsbyImage
          className={cx(styles.image, { [styles.isSoldOut]: isSoldOut })}
          objectFit="contain"
          image={getImage(product.featuredMedia.preview.image)}
          alt={product.title}
        />
      )}
      <h2 className={styles.title}>{product.title}</h2>
      <div className={styles.price}>
        {isOnSale ? <s>{compareAtPrice}</s> : null}
        <span
          className={cx({
            [styles.onSale]: isOnSale,
            [styles.isSoldOut]: isSoldOut,
          })}
        >
          {price}
        </span>
        {isSoldOut ? (
          <span className={styles.soldOut}>
            <FormattedMessage id="products.product.sold_out" />
          </span>
        ) : null}
      </div>
    </Link>
  );
}
