import React from 'react';

import ProductPreview from 'components/Product/ProductPreview';

import * as styles from './SearchResults.module.css';

const SearchResults = ({ results }) => {
  const noResult = results?.length === 0;
  if (noResult) return null;

  return (
    <ul className={styles.list}>
      {results?.map((result) => {
        if (!result.node) return null;
        const collection = result.node.collections[0];
        if (!collection) return null;

        return (
          <li key={result.node.id}>
            <ProductPreview path={collection.handle} product={result.node} />
          </li>
        );
      })}
    </ul>
  );
};

export default SearchResults;
