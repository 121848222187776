const SHOP = process.env.GATSBY_SITE_SHOP;
const LOCAL = SHOP === 'us' ? 'en-US' : 'ja-JP';
const FALLBACK_CURRENCY = SHOP === 'us' ? 'USD' : 'JPY';

export function getPrice(product) {
  const {
    variants: [variant],
    priceRangeV2: { minVariantPrice },
  } = product;

  const price = Intl.NumberFormat(LOCAL, {
    currency: minVariantPrice?.currencyCode || FALLBACK_CURRENCY,
    style: 'currency',
  }).format(variant.price);

  const compareAtPrice = Intl.NumberFormat(LOCAL, {
    currency: minVariantPrice?.currencyCode || FALLBACK_CURRENCY,
    style: 'currency',
  }).format(variant?.compareAtPrice);

  const isOnSale = !!variant?.compareAtPrice;

  return { price, compareAtPrice, isOnSale };
}
