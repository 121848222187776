import getBase64DecodedId from 'utils/getBase64DecodedId';

const SHOP = process.env.GATSBY_SITE_SHOP;
const CURRENCY_CODE = SHOP === 'jp' ? 'JPY' : 'USD';

const defaultEventObj = {
  event: 'customEvent',
  ecommerce: {
    data: {
      category: 'EnhancedEcommerce',
    },
  },
};

const eventObjMap = {
  impression: ({ products, collection }) => {
    return {
      ecommerce: {
        data: {
          category: 'EnhancedEcommerce',
          action: 'Product Viewed',
          label: 'Product Collection Page',
        },
        currencyCode: CURRENCY_CODE,
        impressions: products.map((product, index) => ({
          name: product.node.title,
          id: getBase64DecodedId(product.node.shopifyId),
          brand: product.node.vendor,
          category: product.node.tags.join('/'),
          list: `Collection Page ${collection.title}`,
          position: index + 1,
        })),
      },
    };
  },
  click: ({ product, index, listName = 'Default List' }) => {
    return {
      ecommerce: {
        data: {
          category: 'Ecommerce',
          action: 'Product Clicked',
          label: 'Product Collection Page',
        },
        click: {
          actionField: { list: listName },
          products: [
            {
              name: product.title,
              id: getBase64DecodedId(product.shopifyId),
              brand: product.vendor,
              category: product.tags.join('/'),
              position: index + 1,
            },
          ],
        },
      },
    };
  },
  detail: ({ product, variant }) => {
    return {
      ecommerce: {
        data: {
          category: 'EnhancedEcommerce',
          action: 'Product Viewed',
          label: 'Product Detail Page',
        },
        currencyCode: CURRENCY_CODE,
        detail: {
          products: [
            {
              name: product.title,
              id: getBase64DecodedId(product.shopifyId),
              price: variant?.price || product.variants[0].price,
              brand: product.vendor,
              category: product.tags.join('/'),
              variant: variant?.title || product.variants[0].title,
              position: 1,
            },
          ],
        },
      },
    };
  },
  addToCart: ({ product, variant, quantity }) => {
    return {
      ecommerce: {
        data: {
          category: 'EnhancedEcommerce',
          action: 'Add to Cart',
          label: 'Product Detail Page',
          value: variant.price * quantity,
        },
        currencyCode: CURRENCY_CODE,
        add: {
          products: [
            {
              id: getBase64DecodedId(variant.shopifyId),
              name: `${product.title} - ${variant.title}`,
              brand: product.vendor,
              category: product.tags.join('/'),
              position: 1,
              price: variant.price,
              quantity,
              variant: variant.title,
            },
          ],
        },
      },
    };
  },
  removeFromCart: ({ item }) => {
    return {
      ecommerce: {
        data: {
          category: 'EnhancedEcommerce',
          action: 'Remove from Cart',
          label: 'Product Detail Page',
        },
        remove: {
          products: [
            {
              id: getBase64DecodedId(item.variant.id),
              name: `${item.title} - ${item.variant.title}`,
              brand: 'Richardson Shop',
              position: 1,
              price: item.variant.price,
              quantity: item.quantity,
              variant: item.variant.title,
            },
          ],
        },
      },
    };
  },
  checkout: ({ items }) => {
    return {
      ecommerce: {
        data: {
          category: 'EnhancedEcommerce',
          action: 'Checkout',
          label: 'Cart Page',
        },
        checkout: {
          products: items.map((item) => ({
            name: `${item.title} - ${item.variant.title}`,
            id: getBase64DecodedId(item.variant.id),
            price: item.variant.price,
            brand: 'Richardson Shop',
            variant: item.variant.title,
            quantity: item.quantity,
          })),
        },
      },
    };
  },
};

// trackEvent('addToCard', { ...data });
export default function trackEvent(eventType, data) {
  if (window?.dataLayer) {
    dataLayer.push({ ecommerce: null }); // Clear ecommerce object
    dataLayer.push({ ...defaultEventObj, ...eventObjMap[eventType](data) });
  }
}
