import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import * as styles from './SearchBar.module.css';

export default function SearchBar({ searchQuery, setSearchQuery }) {
  const intl = useIntl();

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className={styles.container}
    >
      <label htmlFor="search-bar">
        <span className={styles.visuallyHidden}>Search products</span>
      </label>
      <div className={styles.field}>
        <input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="search"
          id="search-bar"
          placeholder={intl.formatMessage({ id: 'general.search.placeholder' })}
          name="search"
        />
      </div>
      <button type="submit" className={styles.submitButton}>
        <FormattedMessage id="general.search.submit" />
      </button>
    </form>
  );
}
