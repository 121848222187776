import React, { useState } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import { useFlexSearch } from 'react-use-flexsearch';
import { useIntl } from 'react-intl';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';
import SearchBar from 'components/SearchBar';
import SearchResults from 'components/SearchResults';
import useSiteMetadata from 'hooks/useSiteMetadata';

import * as styles from './search.module.css';

export const query = graphql`
  query SearchQuery {
    localSearchPages {
      index
      store
    }

    allShopifyProduct {
      edges {
        node {
          id
          title
          handle
          featuredMedia {
            id
            preview {
              image {
                altText
                gatsbyImageData(aspectRatio: 1, width: 400)
              }
            }
          }
          collections {
            id
            handle
          }
          variants {
            id
            price
            compareAtPrice
            availableForSale
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

const Search = ({ data, location }) => {
  const { siteUrl } = useSiteMetadata();
  const intl = useIntl();

  const { index, store } = get(data, 'localSearchPages', {});
  const products = get(data, 'allShopifyProduct.edges', []);

  const [searchQuery, setSearchQuery] = useState('');

  const resultIds = useFlexSearch(searchQuery, index, store).map((i) => i.id);
  const results = products.filter((p) => resultIds.includes(p.node.id));

  return (
    <Layout location={location}>
      <GatsbySeo
        title={`Search: ${searchQuery}`}
        canonical={`${siteUrl}${location.pathname}`}
      />
      <div className={styles.container}>
        <h1 className={styles.title}>
          {intl.formatMessage({ id: 'general.search.search_for' })}
          {searchQuery
            ? results.length
              ? searchQuery
              : intl.formatMessage({ id: 'general.search.no_results' })
            : ''}
        </h1>

        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <SearchResults results={results} />
      </div>
    </Layout>
  );
};

export default Search;
